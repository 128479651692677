import amantApp from "../amant_app";
import VimeoPlayer from "@vimeo/player";
import { assign } from "lodash";
import * as util from "../utilities";

export class AmantVideo {
  constructor(element) {
    this.element = element;
    this.poster = element.querySelector(".js-amant-video-poster");
    this.playPauseButton = element.querySelector(".js-amant-video-play-pause");
    this.iframeHolder = element.querySelector(".js-amant-video-iframe-holder");

    // read config
    this.config = {};
    this.config.autoplay =
      !util.prefersReducedMotion() &&
      this.element.hasAttribute("data-autoplay");
    this.config.vimeoID = this.element.getAttribute("data-vimeo-id");
    this.config.hideVimeoControls = this.element.hasAttribute(
      "data-hide-vimeo-controls"
    );

    if (!this.config.vimeoID) {
      console.warn(
        "Error: Unable to initialize video. Vimeo ID missing.",
        this.element
      );
      return false;
    }

    // default state
    this.state = {};
    this.state.paused = true;
    this.state.initialized = false;
    this.state.posterVisible = true;
    this.state.posterTransition = true; // NOTE: used to apply a modifier class that ensure `visibilty: visible` through the entire transition

    // Get poster from Vimeo if no image was manually provided
    // (unless we're autoplaying the video, then it's not worth it)
    if (!this.poster.querySelector("img, picture") && !this.config.autoplay) {
      AmantVideo.getVimeoThumbnail(this.config.vimeoID).then((url) => {
        const img = document.createElement("img");
        img.src = url;
        this.poster.appendChild(img);
      });
    }

    // initialize vimeo
    const { iframe, player } = this.initializeVimeo();
    this.iframe = iframe;
    this.player = player;

    // events
    player
      .ready()
      .then(() => {
        this.update({
          initialized: true,
        });
      })
      .catch((err) => {
        console.error(err);
      });

    // Hide the poster once the video has been played (useful for autoplaying)
    this.player.on("play", (e) => {
      if (!this.state.posterVisible) {
        return;
      }

      this.player.on("progress", (e) => {
        if (e.duration > 0) {
          this.update({
            posterVisible: false,
            posterTransition: true,
          });
          // NOTE: removes all progress handlers. If other progress handlers are needed, pass in the handler itself
          // https://developer.vimeo.com/player/sdk/reference#remove-an-event-listener-from-a-player
          this.player.off("progress");
        }
      });
    });

    this.player.on("play", (e) => {
      // The user may be interacting with the Vimeo controls directly,
      // so make sure the state is accurate
      this.update({
        paused: false,
      });

      // On mobile, we should automatically enter fullscreen
      if (amantApp.state.breakpoint === "1col") {
        this.iframe.requestFullscreen();
      }
    });

    this.player.on("pause", (e) => {
      // The user may be interacting with the Vimeo controls directly,
      // so make sure the state is accurate
      this.update({
        paused: true,
      });
    });

    // TODO: when designs are made, add controls in addition to the poster's play() functionality
    if (this.poster) {
      // Clicking the poster should play the video
      this.poster.addEventListener("click", (e) => {
        this.play();
      });

      this.poster.addEventListener("transitionend", (e) => {
        if (!e.propertyName == "opacity") {
          return;
        }

        this.update({
          posterTransition: false,
        });
      });
    }
  }

  update(update) {
    assign(this.state, update);
    this.render();
  }

  render() {
    util.toggleClassName(
      this.element,
      !this.state.posterVisible,
      "amant-video--poster-dismissed"
    );
    util.toggleClassName(
      this.element,
      this.state.posterTransition,
      "amant-video--poster-transition"
    );
    util.toggleClassName(
      this.element,
      this.state.paused,
      "amant-video--paused"
    );
    util.toggleClassName(
      this.element,
      !this.state.paused,
      "amant-video--playing"
    );
  }

  initializeVimeo() {
    const urlParams = {
      title: 0,
      byline: 0,
      portrait: 0,
    };
    if (this.config.autoplay && this.config.hideVimeoControls) {
      urlParams.background = 1;
    } else if (this.config.autoplay) {
      urlParams.autoplay = 1;
      urlParams.muted = 1;
    } else if (this.config.hideVimeoControls) {
      urlParams.controls = 0;
    };

    const vimeoIDExtended = this.config.vimeoID.split("/");
    let hParam = "";
    if (vimeoIDExtended[1]){
      hParam = (vimeoIDExtended[1].length > 0) ? `h=${vimeoIDExtended[1]}&` : "";
      this.config.vimeoID = vimeoIDExtended[0];
    }


    const urlParmsStr = Object.entries(urlParams)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");

    const iframe = document.createElement("iframe");
    iframe.setAttribute("allow", "autoplay");
    iframe.src = `https://player.vimeo.com/video/${this.config.vimeoID}?${hParam}${urlParmsStr}`;
    this.iframeHolder.appendChild(iframe);
    const player = new VimeoPlayer(iframe);

    return {
      iframe,
      player,
    };
  }

  play() {
    if (!this.player || !this.state.paused) {
      return;
    }

    this.player.play().catch((err) => {
      console.error(err);
    });
  }

  pause() {
    if (!this.player || this.state.paused) {
      return;
    }

    this.player.pause().catch((err) => {
      console.error(err);
    });
  }

  static getVimeoThumbnail(vimeoID) {
    return fetch(`https://vimeo.com/api/v2/video/${vimeoID}.json`)
      .then((res) => res.json())
      .then((data) => {
        if (data.length > 0 && data[0].thumbnail_large) {
          return data[0].thumbnail_large;
        }
        return "";
      });
  }

  static getVimeoID(url) {
    const videoIDMatches = videoURL.match(/(vimeo.com\/)(video\/)?(\d+)/);
    if (!videoIDMatches) {
      console.warn(`Could not extract Vimeo ID from url ${videoURL}`);
      return;
    }
    return videoIDMatches.pop();
  }

  static getInstance(element) {
    return amantVideos.current.find((instance) => instance.element === element);
  }
}

export const amantVideos = {
  current: [],
};

export const init = () => {
  amantApp.addEventListener("turbo:load", {
    name: "initialize-videos",
    handler: () => {
      // NOTE: we may want the constructor to add itself to the exported collection.
      // That would allow other components to instantiate AmantVideos while mainting
      // accuracy in `amantVideo.current`
      amantVideos.current = [
        ...document.querySelectorAll(".js-amant-video"),
      ].map((element) => new AmantVideo(element));
    },
  });

  // Initialize after "save" and "cancel" in Economy edit mode
  // (don't worry about collecting the instances)
  $(document).on("economy:magic_module_updated", (e) => {
    [...e.target.querySelectorAll(".js-amant-video")].map(
      (element) => new AmantVideo(element)
    );
  });

  // const observeChanges = () => {
  //   if (document.querySelector('.media-item__modal')) {
  //     console.log("modal opened");
  //     amantVideos.current = [
  //       ...document.querySelectorAll(".js-amant-video"),
  //     ].map((element) => new AmantVideo(element));
  //     clearInterval(changes);
  //   }
  // }

  // const changes = setInterval( observeChanges, 500);
};
