/*
  This is a reusable component for a basic modal. It can be used as-is
  or it can be extended, which you might consider doing to attach events.

  If a `focusLoopBackTarget` property is attached, this will be used in
  creating a focus lock. If this property points to an element, that element
  will be used as the "circle back point" when the user tabs past the last
  focus-able element.

  NOTE: this component assumes that the modal's open/close transition will be
  via the `transform` property. If that is not the case, this component
  should be extended to have an accurate event handler for removing the
  `transitioning` modifier class.
*/

import amantApp from "../amant_app";
import { assign } from "lodash";
import { toggleClassName } from "../utilities";

export class Modal {
  constructor(element) {
    // Elements
    this.element = element;
    this.closeButton = element.querySelector(".js-modal-close-button");
    this.focusLoopBackTarget = element.querySelector(".js-modal-focus");

    // Default state
    this.state = {};
    this.state.open = false;
    this.state.transitioning = false;
    this.state.focusReleaseTarget = null;
    this.state.focusLocked = false;

    // Config
    this.classNames = {};
    this.classNames.open = "modal--open";
    this.classNames.transitioning = "modal--transitioning";

    // Events

    // When the modal is done transitioning, update the state to reflect that
    // NOTE: this is useful for maintaining `visibility: visible` through the entirety of the transition
    this.element.addEventListener("transitionend", (e) => {
      if (e.target === this.element && e.propertyName === "transform") {
        this.update({
          transitioning: false,
        });
      }
    });

    // If a close button exists, use it to close the modal on click
    if (this.closeButton) {
      this.closeButton.addEventListener("click", () => {
        this.close();
      });
    }

    // Esc key closes
    // NOTE: unnamed so that multiple modals can be instantiated, but that means keyup events will accumulate on the document (or rather they would if turbo was active)
    amantApp.addEventListener("keyup", {
      handler: (e) => {
        if (e.keyCode === 27 && this.state.open) {
          this.close();
        }
      },
    });

    // Clicking outside the modal closes it
    // NOTE: unnamed so that multiple modals can be instantiated, but that means click events will accumulate on the document (or rather they would if turbo was active)
    amantApp.addEventListener("click", {
      name: "modal-outside-click",
      handler: (e) => {
        if (
          this.state.open &&
          !this.state.transitioning &&
          !this.element.contains(e.target)
        ) {
          this.close();
        }
      },
    });
  }

  update(update) {
    assign(this.state, update);
    this.render();
  }

  render() {
    toggleClassName(this.element, this.state.open, this.classNames.open);
    toggleClassName(
      this.element,
      this.state.transitioning,
      this.classNames.transitioning
    );
  }

  /**
   * Open the modal
   * @param {HTMLElement} focusReleaseTarget Element that should be focused when the modal is closed (consider using the element that triggered the opening)
   */
  open(focusReleaseTarget) {
    if (this.state.open) {
      return;
    }

    // If there is no focusReleaseTarget passed in, infer one from the currently focused element
    if (!focusReleaseTarget && document.activeElement) {
      focusReleaseTarget = document.activeElement;
    }

    const update = {
      open: true,
      transitioning: true,
    };

    // Lock focus if the modal instance has a focus loop back target
    if (this.focusLoopBackTarget && !this.state.focusLocked) {
      this.lockFocus();
      update.focusReleaseTarget = focusReleaseTarget;
    }

    this.update(update);
  }

  /**
   * Close the modal
   * @param {HTMLElement} focusReleaseTarget Element that should be focused when the modal is closed (consider using the element that triggered the opening)
   */
  close(focusReleaseTarget) {
    if (!this.state.open) {
      return;
    }

    const update = {
      open: false,
      transitioning: true,
    };

    if (focusReleaseTarget) {
      update.focusReleaseTarget = focusReleaseTarget;
    }

    this.update(update);

    if (this.state.focusLocked && !this.state.open) {
      this.unlockFocus();
    }
  }

  lockFocus() {
    this.unlisten = amantApp.addEventListener("focusin", {
      name: "modal-focus-lock",
      handler: (e) => {
        if (!this.element.contains(e.target)) {
          this.focusLoopBackTarget.focus();
        }
      },
    });

    this.update({
      focusLocked: true,
    });
  }

  unlockFocus() {
    if (this.unlisten) {
      this.unlisten();
    }

    if (this.state.focusReleaseTarget) {
      this.state.focusReleaseTarget.focus();
    }

    this.update({
      focusLocked: false,
    });
  }
}
