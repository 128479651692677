/* JavaScript for NewsletterSignupForm */
import amantApp from "../amant_app";
import * as $ from "jquery";

const INPUT_SCREEN = "input";
const ERROR_SCREEN = "error";
const SUCCESS_SCREEN = "success";

class NewsletterSignupForm {
  constructor(root) {
    this.element = root;
    this.form = root.querySelector(".js-newsletter-form");
    this.emailInput = root.querySelector(".js-newsletter-email");
    this.screens = {};
    this.screens[INPUT_SCREEN] = root.querySelector(".js-newsletter-input-screen");
    this.screens[SUCCESS_SCREEN] = root.querySelector(".js-newsletter-success-screen");
    this.screens[ERROR_SCREEN] = root.querySelector(".js-newsletter-error-screen");
    this.errorMessage = root.querySelector(".js-newsletter-error");

    this.state = {
      screen: INPUT_SCREEN,
      errorMessage: null,
    };

    const apiEndpointBase = this.form.getAttribute("action");

    this.form.addEventListener("submit", (e) => {
      // Disallow typical form submission (which results in page redirect)
      e.preventDefault();


      // Copy form data to new FormData objects. Attempting to mutate the existing FormData does not provide consistent results.
      let formData = new FormData(this.form);
      let generalDataCopy = new FormData();
      let familyDataCopy = new FormData();
      
      // Copy over the relevant fields to the new FormData objects, using the appropriate Mailchimp field names
      // See: view-source:https://amant.us4.list-manage.com/subscribe?u=7a110ef32c974e943af9fe6a6&id=119abfc8b3 for the family newsletter field names
      // See: view-source:https://amant.us4.list-manage.com/subscribe?u=7a110ef32c974e943af9fe6a6&id=15d52d4f1a for the general newsletter field names
      for(const [key, val] of formData.entries()) {
        switch(key) {
          case "u": // Mailchimp ID
            generalDataCopy.append("u", val);
            familyDataCopy.append("u", val);
            break;
          case "MMERGE6": // First Name
            generalDataCopy.append("MERGE6", val);
            familyDataCopy.append("MERGE1", val);
            break;
          case "LNAME": // Last Name
            generalDataCopy.append("MERGE2", val);
            familyDataCopy.append("MERGE2", val);
            break;
          case "EMAIL": // Email
            generalDataCopy.append("MERGE0", val);
            familyDataCopy.append("MERGE0", val);
            break;
          case "GENERAL": // ID
            generalDataCopy.append("id", val);
            break;
          case "FAMILY":  // ID
            familyDataCopy.append("id", val);
        }
      }

      // Send the data to Mailchimp only if the ID field is present for that newsletter.
      if (generalDataCopy.get("id")) {
        $.ajax({
          type: "GET",
          url: apiEndpointBase,
          data: new URLSearchParams(generalDataCopy).toString(),
          dataType: "json",
          contentType: "application/json; charset=utf-8",
          error: (res) => this.handleError(res),
          success: (res) => {
            if (res.result === "error") {
              this.handleError(res);
            } else {
              this.handleSuccess();
            }
          },
        });
      }

      if (familyDataCopy.get("id")) {
        $.ajax({
          type: "GET",
          url: apiEndpointBase,
          data: new URLSearchParams(familyDataCopy).toString(),
          dataType: "json",
          contentType: "application/json; charset=utf-8",
          error: (res) => this.handleError(res),
          success: (res) => {
            if (res.result === "error") {
              this.handleError(res);
            } else {
              this.handleSuccess();
            }
          },
        });
      }
    });

    // With such a big text input, we have to truncate the placeholder text for certain viewports
    this.setPlaceholderToLength();
    amantApp.addEventListener("resize", {
      name: "newsletterSwapPlaceholder",
      handler: () => {
        this.setPlaceholderToLength();
      },
    });

    root.addEventListener("click", (e) => {
      if (e.target.matches(".js-newsletter-retry")) {
        this.handleReset();
      }
    })
  }

  setPlaceholderToLength() {
    let placeholder;
    if (window.innerWidth >= 1100) {
      placeholder = "Enter your email address";
    } else {
      placeholder = "Email address";
    }
    this.emailInput.setAttribute("placeholder", placeholder);
  }

  handleError(res) {
    console.log("Error signing up", res);
    const update = { screen: ERROR_SCREEN };
    if (res.msg) {
      if (res.msg.includes("already subscribed")) {
        update.errorMessage = "You’re already subscribed!";

      } else if (res.msg.includes("invalid")) {
        update.errorMessage = "This email is invalid. Please use another email address.";
      
      } else if (res.msg.includes("timeout")) {
        update.errorMessage = "Signup timed out.";

      } else {
        update.errorMessage = "Something went wrong...";
        
      }
    }
    this.update(update);
  }

  handleSuccess() {
    this.update({ screen: SUCCESS_SCREEN })
  }

  handleReset() {
    this.update({ screen: INPUT_SCREEN })
  }

  update(update) {
    // Merge state
    const previousState = Object.assign({}, this.state);
    Object.assign(this.state, update);
    
    if (update.hasOwnProperty("screen")) {
      this.renderScreenState(previousState);
    }

    if (update.hasOwnProperty("errorMessage")) {
      this.renderErrorMessageState(this.state.errorMessage);
    }
  }
  
  renderScreenState(previousState) {
    const previousScreen = this.screens[previousState.screen];
    const nextScreen = this.screens[this.state.screen];
    
    const endFadeInHandler = () => {
      nextScreen.style.setProperty("transition", "");
      nextScreen.setAttribute("aria-hidden", "false");
      nextScreen.removeEventListener("transitionend", endFadeInHandler);
    };
    nextScreen.addEventListener("transitionend", endFadeInHandler);
    
    const endFadeOutHandler = () => {
      previousScreen.style.setProperty("transition", "");
      previousScreen.setAttribute("aria-hidden", "true");
      previousScreen.style.setProperty("z-index", "-1");
      previousScreen.removeEventListener("transitionend", endFadeOutHandler);
      
      nextScreen.style.setProperty("transition", "opacity 300ms linear");
      nextScreen.style.setProperty("opacity", "1");
      nextScreen.style.setProperty("z-index", "1");
    }

    previousScreen.addEventListener("transitionend", endFadeOutHandler);
    previousScreen.style.setProperty("transition", "opacity 150ms linear");
    previousScreen.style.setProperty("opacity", "0");
  }

  renderErrorMessageState(message) {
    this.errorMessage.innerText = message;
  }
}

export const newsletterSignupForms = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the Tab Module on any given page
  amantApp.addEventListener("turbo:load", () => {
    newsletterSignupForms.current = [
      ...document.querySelectorAll(".js-newsletter-signup"),
    ].map((instance) => new NewsletterSignupForm(instance));
  });
};
