import { Modal } from "./modal";
import amantApp from "../amant_app";

export const newsletterModal = {
  current: null,
};

export const init = () => {
  amantApp.addEventListener("turbo:load", () => {
    const element = document.getElementById("newsletter-modal");
    if (element) {
      newsletterModal.current = new Modal(element);
    }
  });
};
