/* JavaScript for ImageModule */
import amantApp from "../amant_app";
import Flickity from "flickity";

export class ImageModule {
  constructor(element) {
    this.element = element;
    this.carousel = this.element.querySelector(".js-image-module-carousel");
    const positionIndicator = element.querySelector('.js-image-module-position-indicator');
    const leftArrow = element.querySelector('.js-image-module-left-arrow');
    const rightArrow = element.querySelector('.js-image-module-right-arrow');

    const Flickity = require("flickity-bg-lazyload");

    this.flickity = new Flickity(this.carousel, {
      contain: true,
      draggable: false,
      wrapAround: true,
      pageDots: false,
      prevNextButtons: false,
      bgLazyLoad: 3,
      setGallerySize: false,
      draggable: ">2",
    });

    this.flickity.resize();

    let clickAllowed = true;

    this.flickity.on("dragStart", () => {
      clickAllowed = false;
    });

    this.flickity.on("dragEnd", () => {
      setTimeout(() => {
        clickAllowed = true;
      }, 100);
    });

    this.element.addEventListener("click", (e) => {
      if (!clickAllowed || e.target.matches("a, .image-module__caption, .image-module__arrow")) {
        return;
      }

      // NOTE: assumes Image Module is always as wide as the viewport
      if (e.pageX >= window.innerWidth / 2) {
        this.handleForwardClick(e);
      } else {
        this.handlePreviousClick(e);
      }
    });
    
    leftArrow.addEventListener('click', e  =>  {
      e.stopPropagation();
      this.handlePreviousClick(e);
    });

    rightArrow.addEventListener('click', e  =>  {
      e.stopPropagation();
      this.handleForwardClick(e);
    });

    this.flickity.on('select', (currentIndex) => {
      const zeroExclusiveIndex = currentIndex + 1;
      positionIndicator.innerText = `${zeroExclusiveIndex}/${this.flickity.cells.length}`;
      positionIndicator.setAttribute('aria-label', `You are on slide #${zeroExclusiveIndex} of ${this.flickity.cells.length}.`);
    })
  }

  handleForwardClick(e) {
    e.preventDefault();
    this.flickity.next();
  }

  handlePreviousClick(e) {
    e.preventDefault();
    this.flickity.previous();
  }
}

export const imageModules = {
  current: [],
};

export const init = () => {
  // Initialize all instances and save references in an exported collection
  document.addEventListener("turbo:load", () => {
    imageModules.current = [
      ...document.querySelectorAll(
        ".js-image-module:not(.image-module--single)"
      ),
    ].map((instance) => new ImageModule(instance));
  });

  // Initialize after "save" and "cancel" in Economy edit mode
  // (don't worry about collecting the instances)
  $(document).on("economy:magic_module_updated", (e) => {
    [
      ...e.target.querySelectorAll(
        ".js-image-module:not(.image-module--single)"
      ),
    ].map((instance) => new ImageModule(instance));
  });    
};
