import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = ["inner", "header"];

  toggle() {
    const ACTIVE_CLASS_NAME = "accordion__accordion--active";
    let isExpanded = false;

    this.innerTarget.classList.toggle(ACTIVE_CLASS_NAME);

    if ( this.innerTarget.classList.contains(ACTIVE_CLASS_NAME) ) {
      isExpanded = true;
    } else {
      isExpanded = false;
    }

    this.headerTarget.setAttribute("aria-expanded", isExpanded);
  }
}
