import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="media-library-filter"
export default class extends Controller {
  static targets = [ "select"]
  
  filter() {
    // console.log("Event received");
    this.selectTarget.form.requestSubmit();
  }
}
