/* JavaScript for ImageMarqueeModule */
import amantApp from "../amant_app";
import merge from "lodash/merge";
import {
  toggleClassName,
  isEconomyEditMode,
  loadImgProxy,
  clamp,
  isDesktop,
} from "../utilities";
import { amantVideos, AmantVideo } from "../scripts/amant-video";

const MOBILE_ASPECT_RATIO = 4 / 3;

/*
+++++++++++++++++++++++++++++++++++
++ QUICK NOTE ON HOW THIS WORKS: ++
+++++++++++++++++++++++++++++++++++
  1. The slides are looped through and their widths are determined using their aspect ratios. This allows to avoid waiting for them to load.
  2. Using their widths, the slides are assigned positions to be laid out in a row. These positions are applied via css transforms.
  3. Every slide is animated independent of its siblings, but every slide has the same animation and same duration. However, each slide has a delay so that the appearance of a row layout is maintained.
  4. While this animation is in progress, we use js to count the elapsed time and trigger certain functions at certain points (e.g. swapping captions, lazy loading images, etc)
*/

/*
:::::::::::::::::::::::::::
:: PUBLIC-FACING SCRIPTS ::
:::::::::::::::::::::::::::
*/
class ImageMarqueeModule {
  constructor(root) {
    // ELEMENTS
    this.element = root;
    this.stylesContainer = document.createElement("style");
    this.element.appendChild(this.stylesContainer);
    const slideElements = root.querySelectorAll(".js-imm-slide");
    this.captionElement = root.querySelector(".js-imm-caption");
    this.playPauseButton = root.querySelector(".js-imm-play-pause-button");
    this.slides = [...slideElements].map((element, index) => {
      const captionTemplate = element.querySelector(".js-imm-caption-template");
      const caption = captionTemplate ? captionTemplate.innerHTML : "";
      const video = element.querySelector(".js-amant-video");
      const type = video ? "video" : "image";
      const aspectRatio = parseFloat(element.getAttribute("data-aspect-ratio"));
      const draft = {
        element,
        index,
        caption,
        type,
        aspectRatio,
      };
      if (type === "image") {
        draft.baseUrl = element.getAttribute("data-image");
        draft.lowResImageElement = element.querySelector(".js-imm-low-res");
      }

      return draft;
    });
    this.cssID = this.element.getAttribute("id");

    // CONFIGURATION
    this.pixelsPerMillisecond = this.getSpeed();
    this.slideMaxWidth = window.innerHeight * MOBILE_ASPECT_RATIO; // This MUST be updated if more max-widths are added to .imm__image in the CSS.   

    // STATE
    this.state = {};
    // TODO: update on resize
    this.state.height = this.element.offsetHeight;
    this.state.paused = true;
    this.state.slides = [...slideElements].reduce((acc, element, index) => {
      // TODO: update on resize
      // NOTE: slides are styled to have a max-width of 90vw, which is accomplished
      // by `object-fit`. This means the image's aspect ratio isn't guaranteed.
      // We could use slide.element.offsetWidth, but we would have to wait for the image to load,
      // plus want to minimize DOM reflows.
      let width = clamp(
        this.state.height / this.slides[index].aspectRatio,
        0,
        this.slideMaxWidth
      );
      // NOTE: this hack overlaps the images by 1px so we don't get a flickering space between
      // slides as a result of rounding differences.
      width = Math.round(width) - 1;

      const slideState = {
        index,
        width,
        offset: 0,
      };

      if (this.slides[index].type === "image") {
        slideState.highResLoaded = false;
        slideState.highResLoadInProgress = false;
        slideState.highResSrc = "";
        slideState.highResTransitionInProgress = false;
      }

      acc[index] = slideState;
      return acc;
    }, {});
    this.state.raf = null;
    this.state.currentTime = 0;
    this.state.elapsedTime = 0;
    this.state.caption = "";
    this.state.captionIsVisible = false;
    this.state.captionIsSwapping = false;
    merge(this.state, this.getAnimationMeta()); // TODO: refresh on resize

    // EVENTS
    // A play/pause button should toggle the paused state
    this.playPauseButton.addEventListener("click", () => {
      this.togglePlayPause();
    });

    // INITIAL RENDER
    this.render(this.state);
    if (!isEconomyEditMode()) {
      this.play();
    }
    this.initialPaint(this.state);

    // Add the animations to the page
    this.applyAnimations();

    // Debugging purposes
    window.changeSpeed = (rate) => {
      this.pixelsPerMillisecond = rate;
      merge(this.state, this.getAnimationMeta());
      this.applyAnimations();
    };
  }

  loop(currentTime) {
    this.update({ currentTime });

    this.state.raf = requestAnimationFrame(this.loop.bind(this));
  }

  update(update) {
    // Calculate the current position of all slides
    if (update.currentTime) {
      // Calculate the relevant timecode & progress values
      merge(update, this.getSlideProgressValues(update.currentTime));

      let captionInView = false;
      Object.values(this.state.slides).forEach((slide) => {
        // Pull out a bunch properties from the slide's state.
        // The update object will likely only contain `progress` values
        // (because it's a diff, not a complete state object)
        const {
          index,
          loadImgTrigger,
          captionAreaStart,
          captionAreaEnd,
          highResLoaded,
          highResLoadInProgress,
        } = slide;
        const { progress } = update.slides[index];

        // Ensure the update object includes our slide
        update.slides = update.slides || {};
        update.slides[index] = update.slides[index] || {};

        // If the slide is centered on the screen, mark the caption as in view
        // (If a slide enters this range w/ an empty string for its caption,
        // it will hide the caption element)
        captionInView =
          progress >= captionAreaStart && progress <= captionAreaEnd
            ? this.slides[index].caption
            : captionInView;

        // If the slide hasn't yet received a high-res img src, and if it's in view at all...
        if (
          this.slides[index].type === "image" &&
          progress >= loadImgTrigger &&
          !highResLoaded &&
          !highResLoadInProgress
        ) {
          // Set a flag immediately so that we don't make this call over and over again
          update.slides[index].highResLoadInProgress = true;
          this.loadHighResImage(index);
        }
      });

      this.swapCaptions(captionInView);
    }

    this.render(update);
    this.state = merge(this.state, update);
  }

  swapCaptions(caption) {
    // If the caption is unchanged, or is already going through a swapping animation, don't do anything
    if (caption === this.state.caption || this.state.captionIsSwapping) {
      return;
    }

    // If no caption is visible but we want to display one, just display it directly
    if (!this.state.captionIsVisible && caption) {
      this.update({
        caption,
        captionIsSwapping: false,
        captionIsVisible: true,
      });
    }
    // If there already is a caption on display, fade it out, then replace its contents & show it
    else if (this.state.captionIsVisible && caption) {
      const handler = (e) => {
        if (e.propertyName !== "opacity" || e.target !== this.captionElement) {
          return;
        }
        this.update({
          caption,
          captionIsSwapping: false,
          captionIsVisible: true,
        });
        this.captionElement.removeEventListener("transitionend", handler);
      };
      this.captionElement.addEventListener("transitionend", handler);
      this.update({
        captionIsSwapping: true,
        captionIsVisible: false,
      });
    }
    // If there no applicable caption, hide it all
    else if (!caption) {
      this.update({
        captionIsVisible: false,
        captionIsSwapping: false,
      });
    }
  }

  initialPaint() {
    const _this = this;
    // If slide is past the trigger at which we load the hi-res image, load the hi-res image
    // Otherwise load a low-res image
    Object.values(this.state.slides).forEach((slide) => {
      const {
        index,
        loadImgTrigger,
        highResLoaded,
        highResLoadInProgress,
        progress,
      } = slide;

      const props = _this.slides[index];
      if (props.type !== "image" || highResLoaded || highResLoadInProgress) {
        return;
      }

      if (progress >= loadImgTrigger) {
        this.loadHighResImage(index);
      } else {
        this.loadLowResImage(index);
      }
    });
  }

  // NOTE: Unlike other modules in this app, this render function is given a diff to minimize the DOM manipulations (e.g. we don't want to update the caption every time render is called, because a lot of calls are just to render a frame of the animation.)
  // TODO: considering writing this with a real framework...
  render(update) {
    if (update.hasOwnProperty("paused")) {
      toggleClassName(this.element, update.paused, "imm--paused");
      const animationPlayState = update.paused ? "paused" : "running";
      this.slides.forEach((slide) => {
        slide.element.style.animationPlayState = animationPlayState;
      });
    }

    if (update.slides) {
      this.slides.forEach((slide) => {
        const slideUpdate = update.slides[slide.index];

        if (!slideUpdate) {
          return;
        }

        if (slideUpdate.hasOwnProperty("highResTransitionInProgress")) {
          toggleClassName(
            slide.element,
            slideUpdate.highResTransitionInProgress,
            "imm__slide--image-swap-in-progress"
          );
        }

        if (slideUpdate.lowResImg) {
          slideUpdate.lowResImg.classList.add("imm__image");
          slideUpdate.lowResImg.classList.add("imm__image--low-res");
          slide.element.appendChild(slideUpdate.lowResImg);
        }

        if (slideUpdate.highResSrc) {
          slideUpdate.highResSrc.classList.add("imm__image");
          slideUpdate.highResSrc.classList.add("imm__image--high-res");
          slide.element.appendChild(slideUpdate.highResSrc);
        }

        if (slideUpdate.highResLoaded) {
          slide.element.classList.add("imm__slide--loaded");
        }
      });
    }

    if (update.hasOwnProperty("caption")) {
      this.captionElement.innerHTML = update.caption;
    }

    if (update.hasOwnProperty("captionIsVisible")) {
      this.captionElement.style.opacity = update.captionIsVisible ? 1 : 0;
    }
  }

  // Add the animations to the page
  applyAnimations() {
    for (const slideIndex in this.state.slides) {
      const slide = this.state.slides[slideIndex];
      const slideElement = this.slides[slideIndex].element;
      slideElement.style.animationName = this.state.animationName;
      slideElement.style.animationDuration = `${this.state.totalDuration}ms`;
      slideElement.style.animationDelay = slide.delayWithUnit;
    }
    this.stylesContainer.innerHTML = this.state.animationDeclaration;
  }

  togglePlayPause() {
    if (this.state.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  play() {
    this.update({
      paused: false,
      currentTime: performance.now(), // returns high res timestamp
    });

    this.state.raf = requestAnimationFrame(this.loop.bind(this));
  }

  pause() {
    this.update({
      paused: true,
      currentTime: 0,
    });
    cancelAnimationFrame(this.state.raf);
    this.state.raf = null;
  }

  // NOTE: leaving this function in case the speed should be set conditionally
  getSpeed() {
    return 0.03;
  }

  // Expresses a slide's position at a specific point as a percentage of its overall animation
  getProgressValueForTrigger(pixelValue, start, end) {
    return 1 - (pixelValue - end) / (start - end);
  }

  // Calculates the marquee animation (overall translation, duration, and animation CSS)
  getAnimationMeta() {
    let totalWidth = Object.values(this.state.slides).reduce(
      (acc, slide) => (acc += slide.width),
      0
    );
    totalWidth =
      totalWidth >= window.innerWidth ? totalWidth : window.innerWidth;
    const totalDuration = totalWidth / this.pixelsPerMillisecond;

    // Figure out how many seconds to offset the animation so that the
    // first slide is aligned with the viewport's left edge
    const numOfPixelOffsetToStart = -1 * window.innerWidth;
    const initialDelay = numOfPixelOffsetToStart / this.pixelsPerMillisecond;

    // Every slide has the same animation, each with different delays
    const start = window.innerWidth;
    const end = start - totalWidth;
    const animationName = `imm-translation-${this.cssID}`;
    const animationDeclaration = `@keyframes ${animationName} {
      from {
        transform: translateX(${start}px);
      }
      to {
        transform: translateX(${Math.round(end)}px);
      }
    }`;

    // For each slide, figure out its positioning info: start, stop,
    // when its caption  should appear, etc, etc
    let runningOffset = 0;
    const slides = Object.assign({}, this.state.slides);
    for (const slideIndex in slides) {
      const slide = slides[slideIndex];

      const halfSlideWidth = slide.width / 2;
      // The start of the caption area is when the image's center enters the viewport
      // NOTE: right now this is the same as the "inView" range, but I will leave them
      // separate because we keep adjusting the caption values...
      const captionAreaStart = this.getProgressValueForTrigger(
        window.innerWidth * 1 - halfSlideWidth,
        start,
        end
      );
      // The end of the caption area is when the image's center exits the viewport
      // NOTE: right now this is the same as the "inView" range, but I will leave them
      // separate because we keep adjusting the caption values...
      const captionAreaEnd = this.getProgressValueForTrigger(
        -halfSlideWidth,
        start,
        end
      );
      const loadImgTrigger = this.getProgressValueForTrigger(
        window.innerWidth * 2,
        start,
        end
      );

      const delay = runningOffset / this.pixelsPerMillisecond + initialDelay;
      const delayWithUnit = `${delay}ms`;

      runningOffset += slide.width;
      merge(slide, {
        loadImgTrigger,
        captionAreaStart,
        captionAreaEnd,
        delay,
        delayWithUnit,
        progress: 0,
      });
    }

    return {
      start,
      end,
      animationDeclaration,
      animationName,
      totalDuration,
      slides,
    };
  }

  // Given the current time, calculate elapsed time & progress values
  getSlideProgressValues(currentTime) {
    // On loop updates, update the elapsed time.
    // If the animation is starting from a pause state, reuse the last elapsed time
    const elapsedTime = this.state.currentTime
      ? this.state.elapsedTime + (currentTime - this.state.currentTime)
      : this.state.elapsedTime;

    // Assign a progress value to each slide
    const slides = {};
    for (const slideIndex in this.state.slides) {
      const { delay } = this.state.slides[slideIndex];
      slides[slideIndex] = {};
      slides[slideIndex].progress =
        ((elapsedTime - delay) % this.state.totalDuration) /
        this.state.totalDuration;
    }
    return {
      elapsedTime,
      slides,
    };
  }

  getHighResImageURL(baseUrl) {
    const height = amantApp.state.breakpoint === "1col" ? 750 : 1500;
    return `${baseUrl}&h=${height}`;
  }

  getLowResImageURL(baseUrl) {
    return `${baseUrl}&w=150`;
  }

  // Given a slide index, load a high res image and update the state
  loadHighResImage(slideIndex) {
    if (this.slides[slideIndex].type !== "image") {
      return;
    }

    const baseUrl = this.slides[slideIndex].baseUrl;
    const highResImageURL = this.getHighResImageURL(baseUrl);

    loadImgProxy(highResImageURL).then((img) => {
      // We are going to fade in the high res image with an animation.
      // When the animation is complete, we should fully hide the low-res image.
      img.addEventListener("animationend", (e) => {
        if (e.animationName !== "imm-fade-in") {
          return;
        }

        const update = {};
        update.slides = {};
        update.slides[slideIndex] = { highResTransitionInProgress: false };
        setTimeout(() => {
          this.update(update);
        }, 2000);
      });

      // Pass the loaded image into the render function so it appears on the page.
      const update = {};
      update.slides = {};
      update.slides[slideIndex] = {};
      update.slides[slideIndex].highResLoaded = true;
      update.slides[slideIndex].highResLoadInProgress = false;
      update.slides[slideIndex].highResTransitionInProgress = true;
      update.slides[slideIndex].highResSrc = img;
      this.update(update);
    });
  }

  // Given a slide index, load a high res image and update the state
  loadLowResImage(slideIndex) {
    if (this.slides[slideIndex].type !== "image") {
      return;
    }

    const baseUrl = this.slides[slideIndex].baseUrl;
    const lowResImageURL = this.getLowResImageURL(baseUrl);

    loadImgProxy(lowResImageURL).then((img) => {
      // Pass the loaded image into the render function so it appears on the page.
      const update = {};
      update.slides = {};
      update.slides[slideIndex] = {};
      update.slides[slideIndex].lowResImg = img;
      this.update(update);
    });
  }
}

/*
::::::::::::::::::::::::::
:: ECONOMY EDIT SCRIPTS ::
::::::::::::::::::::::::::
*/
const getSetColor = (str) => {
  if (!str) return "transparent";
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let rgb = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }

  const dot = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
  const brightness = Math.round(
    (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
  );
  const text = brightness > 125 ? "black" : "white";
  return { dot, text };
};

const applyColorCoding = (indicator, input) => {
  const color = getSetColor(input.value);
  indicator.style.backgroundColor = color.dot;
  indicator.style.color = color.text;
};

const initEdit = () => {
  if (!isEconomyEditMode) {
    return;
  }

  const slideSelector = ".nested-fields";
  const indicatorSelector = ".nested-fields-position-indicator";
  const inputSelector = ".magic_module_image_marquee_module_slides_set input";

  // Apply color coding when the module is first edited
  $(document).on("economy:init:fields", (e) => {
    if (!e.target.matches(".js-image-marquee")) {
      return;
    }
    const slides = e.target.querySelectorAll(slideSelector);
    [...slides].forEach((slide) => {
      const indicator = slide.querySelector(indicatorSelector);
      const input = slide.querySelector(inputSelector);
      applyColorCoding(indicator, input);
    });
  });

  // Update color coding on subsequent changes
  amantApp.addEventListener("change", {
    name: "image-marquee-edit-update",
    handler: (e) => {
      if (!e.target.matches(inputSelector)) {
        return;
      }

      const slide = e.target.closest(slideSelector);
      const indicator = slide.querySelector(indicatorSelector);
      applyColorCoding(indicator, e.target);
    },
  });
};

/*
:::::::::::::
:: EXPORTS ::
:::::::::::::
*/

export const imageMarqueeModules = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the Tab Module on any given page
  amantApp.addEventListener("turbo:load", () => {
    initEdit();

    imageMarqueeModules.current = [
      ...document.querySelectorAll(".js-image-marquee-module"),
    ].map((instance) => new ImageMarqueeModule(instance));
  });
};
