import amantApp from "../amant_app";

/* JavaScript for ImageGridModule */
class ImageGridModule {
  constructor(root) {
    this.element = root;
    const items = [...root.querySelectorAll(".js-image-grid-item")].map(
      (item) => {
        const draft = { item };
        draft.image = item.getAttribute("data-lightbox-image");
        draft.caption = item.getAttribute("data-lightbox-caption");
        return draft;
      }
    );
  }
}

export const imageGridModules = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the Image Grid Module on any given page
  amantApp.addEventListener("turbo:load", () => {
    imageGridModules.current = [
      ...document.querySelectorAll(".js-image-grid-module"),
    ].map((instance) => new ImageGridModule(instance));
  });
};
