/* JavaScript for MarqueeModule */
import amantApp from "../amant_app";
import merge from "lodash/merge";
import { toggleClassName } from "../utilities";
import anime from 'animejs/lib/anime.es.js';
import { round } from "lodash";

const ANIMATION_DURATION_BASELINE = 15; //seconds

class MarqueeModule {
  constructor(element) {
    // Select view elements
    this.element = element;
    this.marqueeType = document.body.classList.contains("controller--led_pages") ? "LED" : "default";
    this.innerWrapper = this.element.querySelector(".js-marquee-module-inner");
    this.textWrapper = this.innerWrapper.querySelector(".js-marquee-module-text-wrapper");
    this.inputText = this.textWrapper.querySelectorAll(".js-marquee-module-text");
    this.playPauseButton = element.querySelector(".js-marquee-module-play-pause-button");

    // Configure this marquee
    this.animatesRightToLeft = this.textWrapper.classList.contains("marquee-module__right-to-left");
    this.animatesLeftToRight = this.textWrapper.classList.contains("marquee-module__left-to-right");
    this.animatesHorizontally = this.textWrapper.classList.contains("marquee-module__right-to-left") || this.textWrapper.classList.contains("marquee-module__left-to-right");
    this.animatesTopToBottom = this.textWrapper.classList.contains("marquee-module__top-to-bottom");
    this.animatesFadeInOut = this.textWrapper.classList.contains("marquee-module__fade-in-out");
    this.speedValue = parseInt(this.textWrapper.dataset.speed)*1000;
    this.attachedAnimation = null;
    
    this.timesLooped = 0;

    // State for default marquee configuration. (Not used on LED Marquees)
    this.state = {
      paused: false,
      animationPlayState: "running",
      newlyFirst: true
    };

    // Custom event to fire when the animation loops
    this.loopEvent = new CustomEvent('loopevent', {
      detail: this.element,
      bubbles: true,
      cancelable: true,
      composed: false
    });
    
    /* 
      --------------
      INITIAL RENDER
      --------------
    */

    // Initialize the AnimeJS animations.
    if (this.animatesTopToBottom) {
      this.animateTopToBottom();
    } 
    else if (this.animatesFadeInOut) {
      this.animateFadeInOut();
    }
    else if (this.animatesRightToLeft) {
      this.animateRightToLeft();
    }
    else if (this.animatesLeftToRight) {
      this.animateLeftToRight();
    }
    else if (this.marqueeType == "default") {
      /**
       * Took out these items and the text clone further below so that we get only one text wrapper, removing the phase effect from overlapping marquees.
       */
      let textWidth = this.textWrapper.offsetWidth; // this.textWrapper needs to be greater than this.element.offsetWidth
      while (textWidth <= this.innerWrapper.offsetWidth) {
        this.inputText.forEach((text, i) => { // clone all of the text nodes and add them until desired width is achieved
          const textClone = text.cloneNode(true);
          this.textWrapper.append(textClone);
        });
        textWidth = this.textWrapper.offsetWidth;
      }
    }

    if (!this.animatesRightToLeft && this.animatesHorizontally || this.marqueeType == "default") {
      this.textWrapper.style.animationDuration = this._getAnimationDuration();
    }

    // we need two textWrappers one after the other to make an infinite scrolling animation
    // also only if the animation is a horizontal animation.
    // Not active on LED Marquee
    if(this.marqueeType == "default") {
      this.textWrapperClone = this.textWrapper.cloneNode(true);
      this.innerWrapper.append(this.textWrapperClone);
    }
    this.render(this.state);

    /* 
      ------
      EVENTS
      ------ 
    */

    // A play/pause button should toggle the paused state
    this.playPauseButton.addEventListener("click", () => {
      this.togglePlayPause();
    });
  } // End Constructor

  /* 
    ---------------
    DEFAULT METHODS
    ---------------
  */

  render(update) {
    toggleClassName(this.element, update.paused, "marquee-module--paused");

    //apply the animation state to the textwrapper and its clone
    this.textWrapper.style.animationPlayState = update.animationPlayState;
    if (this.marqueeType == "default") {
      this.textWrapperClone.style.animationPlayState = update.animationPlayState;
    }
  }

  update(update) {
    if (update.paused) {
      update.animationPlayState = "paused";
    } else {
      update.animationPlayState = "running";
    }

    this.render(update);
    this.state = merge(this.state, update);
  }

  togglePlayPause() {
    if (this.state.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  togglePlayPauseTimer(playTime, pauseTime){
    if (this.state.paused) {
      setTimeout(this.play(), pauseTime); // Play after waiting pauseTime milliseconds.
    } else {
      setTimeout(this.pause(), playTime); // Pause after waiting playTime milliseconds.
    }
    // Currently this will set the timeouts every time the function is called. Not suitable for a while loop...
  }

  play() {
    this.update({
      paused: false,
    });
  }

  pause() {
    this.update({
      paused: true,
    });
  }

  animateTopToBottom() {
    this.innerWrapper.style.justifyContent = "center";
    this.innerWrapper.style.height = "2rem";
    this.textWrapper.style.visibility = "visible";
    this.textCount = this.inputText.length;

    const tl = anime.timeline({
      targets: this.textWrapper,
      easing: "linear",
      loop: true,
      loopComplete: () => {
        this.element.dispatchEvent(this.loopEvent);
      }
    })
    
    tl.add({
      opacity: [0, 1],
      duration: 1000
    })

    for (let index = 0; index < this.textCount+1; index++) {
      tl.add({
        translateY: [`${-index*100}%`, `${-(index+1)*100}%`],
        duration: this.speedValue
      }, `+=${this.speedValue}`)
    }

    this.attachedAnimation = tl;
  }

  animateFadeInOut() {
    this.innerWrapper.style.justifyContent = "center";
    this.innerWrapper.style.height = "2rem";
    this.textWrapper.style.visibility = "visible";
    this.textCount = this.inputText.length;
    
    const tl = anime.timeline({
      targets: this.textWrapper,
      easing: "linear",
      loop: true,
      begin: () => {
        this.inputText[0].style.display = "none";
      },
      loopComplete: (anim) => {
        setTimeout(() => anim.restart());
        this.element.dispatchEvent(this.loopEvent);
      }
    })

    for (let index = 0; index < this.textCount; index++) {
      tl.add({
        opacity: [0, 1],
        duration: this.speedValue,
        begin: () => {
          this.inputText[index].style.display = "block"
        }
      }, `+=${this.speedValue}`)
      tl.add({
        opacity: [1, 0],
        duration: this.speedValue,
        complete: () => {
          this.inputText[index].style.display = "none"
        }
      }, `+=${this.speedValue}`)
    }
    tl.add({
      complete: () => {
        this.inputText[0].style.display = "block"
      }
    })

    this.attachedAnimation = tl;
  }

  animateRightToLeft() {
    this.attachedAnimation = anime({
      targets: this.textWrapper,
      easing: "linear",
      translateX: ['0%', '-100%'],
      loop: true,
      duration: this.speedValue,
      delay: 1000,
      loopComplete: (anim) => {
        setTimeout(() => anim.restart());
        this.element.dispatchEvent(this.loopEvent);
      }
    })
  }

  animateLeftToRight() {
    this.attachedAnimation = anime({
      targets: this.textWrapper,
      easing: "linear",
      translateX: ['-100%', '0%'],
      loop: true,
      duration: this.speedValue,
      delay: 1000,
      loopComplete: (anim) => {
        setTimeout(() => anim.restart());
        this.element.dispatchEvent(this.loopEvent);
      }
    })
  }

  restartAnimation() {
    this.attachedAnimation.restart();
  }

  /* 
    --------------
    SHARED METHODS
    --------------
  */

  _getAnimationDuration() {
    let moduleSpeed = parseInt(this.textWrapper.dataset.speed) || 0;
    let duration =
      ((ANIMATION_DURATION_BASELINE + moduleSpeed) / window.innerWidth) *
      this.textWrapper.offsetWidth;
    return `${duration}s`;
  }
}

/*
:::::::::::::
:: EXPORTS ::
:::::::::::::
*/

export const marqueeModules = {
  current: [],
};

export const init = () => {
  // Place your magic module init code here
  amantApp.addEventListener("turbo:load", () => {
    marqueeModules.current = [
      ...document.querySelectorAll(".js-marquee-module"),
    ].map((instance) => new MarqueeModule(instance));
  });
};