// https://www.filestack.com/docs/concepts/pickers/web/

import amantApp from "../amant_app";
import * as filestack from "filestack-js";

const apiKey = "AFx79KQcETNaOd4KM3pL4z";
const client = filestack.init(apiKey);
const maxSizeMegabytes = 10;
let activeUploaderElement = null;
const updateInputs = (file, element) => {
  const urlInput = element.querySelector('.file-uploader__url');
  const s3Input = element.querySelector('.file-uploader__s3');
  const buttonLabel = element.querySelector('.file-uploader__label');
  const filenameInput = element.querySelector('.file-uploader__filename');
  const mimetypeInput = element.querySelector('.file-uploader__mimetype');
  const sizeInput = element.querySelector('.file-uploader__size');

  urlInput.value = file.url;
  s3Input.value = window.AMANT_S3_BUCKET + '/' + file.key;
  buttonLabel.innerHTML = file.filename;
  filenameInput.value = file.filename;
  mimetypeInput.value = file.mimetype;
  sizeInput.value = file.size;
};
const options = {
  fromSources: ["local_file_system"],
  accept: [".pdf"],
  storeTo: {
    container: window.AMANT_S3_BUCKET,
    region: 'us-east-1'
  },
  onFileSelected: file => {
    // If you throw any error in this function it will reject the file selection.
    // The error message will be displayed to the user as an alert.
    if ( file.size > 1000 * 1000 * maxSizeMegabytes ) {
      alert(`File too big, select something smaller than ${maxSizeMegabytes}MB`);
      throw new Error(`File too big, select something smaller than ${maxSizeMegabytes}MB`);
    }
  },
  onFileUploadFinished: file => {
    updateInputs(file, activeUploaderElement);
    activeUploaderElement = null;
  }
};

export class FileUploader {
  constructor(element) {
    this.element = element;
    this.element.addEventListener("click", (e) => {
      activeUploaderElement = element;
      this.openPicker();
    });
  }

  openPicker() {
    client.picker(options).open();
  }
}

export const fileUploaders = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the FileUploader on any given page
  document.addEventListener("turbo:load", () => {
    fileUploaders.current = [
      ...document.querySelectorAll(".file-uploader"),
    ].map((instance) => new FileUploader(instance));
  });
};
