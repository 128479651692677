import { filter } from "lodash";
import amantApp from "../amant_app";

export class FormAutoSubmitter {
  constructor(element) {
    this.element = element;
    this.parameter = null;

    this.element.addEventListener("change", (e) => {
      this.parameter = `${e.target.name}=${e.target.value}`
      this.element.requestSubmit();
    });
    
    this.element.addEventListener("submit", (e) => {
      if (window.location.search.includes('timeframe')) {
        console.log(e);
        e.preventDefault();
        window.location.search = this.filterParams(window.location.search.split("&"));
      }
    })
  }

  openPicker() {
    client.picker(options).open();
  }

  filterParams(params) {
    const filteredParams = params.slice(0,1);
    console.log(filteredParams, this.parameter);
    filteredParams.push(this.parameter);

    return filteredParams.join("&");

  }
}

export const FormAutoSubmitters = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the FormAutoSubmitter on any given page
  document.addEventListener("turbo:load", () => {
    FormAutoSubmitters.current = [
      ...document.querySelectorAll(".js-form-auto-submitter"),
    ].map((instance) => new FormAutoSubmitter(instance));
  });
};
