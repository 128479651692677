import amantApp from "../amant_app";

export const roundTo = (input, numberOfDecimalPlaces = 2) => {
  const multiplier = Math.pow(10, numberOfDecimalPlaces);
  return Math.round(input * multiplier) / multiplier;
};

// TODO: build out a global state so that the results of this can be cached
export const isMobile = () => {
  return amantApp.state.breakpoint === "1col";
};

export const isDesktop = () => {
  return amantApp.state.breakpoint === "3col";
};

export const interpolate = (start, end, weighting) => {
  return (end - start) * weighting + start;
};

export const isEconomyEditMode = () =>
  document.documentElement.classList.contains("edit");

export const loadImgProxy = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.src = url;
  });
};

export const loopAround = (val, min, max) => {
  if (val > max) {
    return min;
  }
  if (val < min) {
    return max;
  }
  return val;
};

export const toggleClassName = (el, boolean, className) => {
  const classListMethod = boolean ? "add" : "remove";
  el.classList[classListMethod](className);
};

export const clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};

export const prefersReducedMotion = () => {
  const reducedMotionMediaQuery = window.matchMedia(
    "(prefers-reduced-motion: reduce)"
  );
  return reducedMotionMediaQuery.matches;
};
