import amantApp from "../amant_app";
import * as accordionModule from "../magic_modules/accordion_module";
import * as audioModule from "../magic_modules/audio_module";
import * as videoModule from "./amant-video";

/* 
  This is a helper script that loads magic module logic onto media archive modals.
  Because the modals do not exist on the page on initial render, magic modules do not
  attach to the DOM nodes necessary for initialization.

  To fix this, we use a MutationObserver to watch for the appendment of a child modal to
  the initially rendered wrapper node. On that mutation, we initialize the magic module
  classes to their respective DOM nodes.
*/

export const init = () => {
  amantApp.addEventListener("turbo:load", () => {

    // Prerequisites for MutationObserver
    const targetNode = document.getElementById('media-archive__modal__wrapper');
    const config = { childList: true };
    const callback = (mutationsList, observer) => {
      for(const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          if (document.querySelector('.media-item__modal')){
            const accordions = document.querySelectorAll('.js-accordion-module');
            if (accordions.length > 0)
              Array.from(accordions).map((accordion) => new accordionModule.AccordionModule(accordion))
              

            const audios = document.querySelectorAll('.js-amant-audio');
            if (audios.length > 0)
              Array.from(audios).map((audio) => new audioModule.AudioModule(audio))
            
            const videos = document.querySelectorAll('.js-amant-video');
            if (videos.length > 0)  
              Array.from(videos).map((video) => new videoModule.AmantVideo(video))
          }
        }
      }
    };

    if(targetNode) {
      const observer = new MutationObserver(callback);
      observer.observe(targetNode, config);
    }
  });  
};
