import amantApp from "../amant_app";

export class SearchBar {
  constructor(element) {
    this.element = element;
    this.searchButton = element.querySelector("a[href$='search']");
    this.searchBreadcrumb = element.querySelector(".site-header__breadcrumb a[href$='search']");
    this.searchBar = element.querySelector("#search-bar");
    this.searchInput = element.querySelector(".search-bar__input");
    this.dismissButton = element.querySelector(".search-bar__dismiss");

    this.searchButton.addEventListener("click", (e) => {
      e.preventDefault(); // Keep the link from opening a new page.

      // Display search bar
      this.searchBar.style.display = "unset";
      this.searchBar.classList.add("search-bar--active")
      document.getElementById("site-header").classList.remove("site-header--expanded");
      // this.searchBar.style.height = "85%";
      this.searchInput.focus();
    });

    if (this.searchBreadcrumb) {
      this.searchBreadcrumb.addEventListener("click", (e) => {
        e.preventDefault(); // Keep the link from opening a new page.

        // Display search bar
        this.searchBar.style.display = "unset";
        this.searchBar.classList.add("search-bar--active")
        document.getElementById("site-header").classList.remove("site-header--expanded");
        // this.searchBar.style.height = "85%";
        this.searchInput.focus();
      });
    }

    this.dismissButton.addEventListener("click", () => {
      this.searchBar.style.display = "none";
      this.searchBar.classList.remove("search-bar--active");
      // this.searchBar.style.height = "0%";
    })

    document.getElementById("application-yield").addEventListener("touchend", () => {
      if (this.searchBar.classList.contains("search-bar--active")) {
        this.searchBar.style.display = "none";
        this.searchBar.classList.remove("search-bar--active");
      };
    })

    this.searchInput.addEventListener("keyup", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        window.location.href = `${window.location.origin}/search?query=${this.searchInput.value}`;
      };
    })
  }
  
}


export const init = () => {
  amantApp.addEventListener("turbo:load", () => {
    const header = document.getElementById("site-header");
    if (header) {
      new SearchBar(header);
    }
  });
};