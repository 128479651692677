import amantApp from "../amant_app";
import { toggleClassName } from "../utilities";

/*
NOTE:
  see Accessibility Developer Guide for notes on accessible modals:
  https://www.accessibility-developer-guide.com/examples/widgets/dialog/ 
*/

const CLASSES = {
  visible: "lightbox--visible",
  transitioning: "lightbox--transitioning",
  showCaption: "lightbox--show-caption",
  imageLoaded: "lightbox--image-loaded",
};
const CAPTION_TIMEOUT = 2000; // ms

class Lightbox {
  constructor(root) {
    this.element = root;
    this.imageElement = root.querySelector("#lightbox-image");
    this.lowResImageElement = root.querySelector("#lightbox-low-res-image");
    this.captionElement = root.querySelector("#lightbox-caption");
    this.closeButton = root.querySelector("#lightbox-close-button");
    this.state = {
      visible: false,
      transitioning: false,
      showCaption: false,
      imageLoaded: false,
      image: null,
      lowResImage: null,
      caption: null,
      whereToFocusOnClose: null,
    };

    this.triggerSelector = "[data-lightbox-image]";
    this.captionDelay = null;

    this._applyAccessibleAttributes();

    this.element.addEventListener("transitionend", (e) => {
      if (
        (e.target === this.element && e.propertyName === "opacity") ||
        (e.target === this.captionElement && e.propertyName === "opacity")
      ) {
        this.update({
          transitioning: false,
        });

        // If the transition leaves us with a collapsed lightbox, clear out the content to preventt flashing on subsequent shows
        if (!this.state.visible) {
          this.clear();
        }
      }
    });

    // Clicking anywhere should close the lightbox
    amantApp.addEventListener("click", {
      name: "lightbox-click-to-close",
      handler: (e) => {
        if (!this.state.visible || this.captionElement.contains(e.target)) {
          return;
        }

        this.hide();
      },
    });

    // Escape key should close the lightbox
    amantApp.addEventListener("keyup", {
      name: "lightbox-esc-key-to-close",
      handler: (e) => {
        if (!this.state.visible) {
          return;
        }

        if (e.keyCode === 27) {
          this.hide();
        }
      },
    });

    // When any item that has a [data-lightbox-image] attribute is clicked open the lightbox
    amantApp.addEventListener("click", {
      name: "lightbox-open",
      handler: (e) => {
        if (
          !e.target.matches(
            `${this.triggerSelector}, ${this.triggerSelector} *`
          )
        ) {
          return;
        }

        // NOTE: if the clicked item is a descendent of a lightbox trigger, traverse upwards until the trigger is found
        // REVIEW: we might not want this functionality...
        // TODO: research `node.closest()` perf
        const item = e.target.matches(this.triggerSelector)
          ? e.target
          : e.target.closest(this.triggerSelector);
        this.show(
          item.getAttribute("data-lightbox-image"),
          item.getAttribute("data-lightbox-caption")
        );
      },
    });

    // "Enter" key presses when lightbox triggers are focused should open the lightbox
    amantApp.addEventListener("keydown", {
      name: "lightbox-keydown-to-open",
      handler: (e) => {
        if (
          !document.activeElement.matches(this.triggerSelector) ||
          e.keyCode !== 13
        ) {
          return;
        }
        this.show(
          document.activeElement.getAttribute("data-lightbox-image"),
          document.activeElement.getAttribute("data-lightbox-caption")
        );
      },
    });

    // If the user mouses over where the caption was, the caption should appear. It should remain visible while the mouse is hovering over the element.
    this.captionElement.addEventListener("pointerenter", (e) => {
      clearTimeout(this.captionDelay);
      this.update({
        showCaption: true,
      });
    });

    // When the mouse leaves the caption bounding box, wait 2s and then hide it again
    this.captionElement.addEventListener("pointerleave", (e) => {
      if (e.target === this.captionElement) {
        this.hideCaptionAfterDelay();
      }
    });

    // Hide the low-res image when the hi-res one has loaded
    this.imageElement.onload = () => {
      this.update({
        imageLoaded: true,
      });
    };

    // Trap focus within the dialog when it is visible
    amantApp.addEventListener("focusin", {
      name: "lightbox-focus-lock",
      handler: (e) => {
        if (!this.state.visible) {
          return;
        }

        if (!this.element.contains(e.target)) {
          this.closeButton.focus();
        }
      },
    });
  }

  update(update) {
    const prevState = Object.assign({}, this.state);
    Object.assign(this.state, update);
    if (
      prevState.visible !== this.state.visible ||
      prevState.showCaption !== this.state.showCaption
    ) {
      this.state.transitioning = true;
    }
    this.render(prevState, this.state);
  }

  render(prevState, state) {
    if (state.visible) {
      this.element.focus();
    } else if (state.whereToFocusOnClose) {
      state.whereToFocusOnClose.focus();
    }

    if (prevState.lowResImage !== state.lowResImage) {
      this.lowResImageElement.setAttribute("src", state.lowResImage);
    }

    if (prevState.image !== state.image) {
      this.imageElement.setAttribute("src", state.image);
    }

    if (prevState.caption !== state.caption) {
      this.captionElement.innerHTML = state.caption;
    }

    toggleClassName(this.element, state.showCaption, CLASSES.showCaption);
    toggleClassName(this.element, state.transitioning, CLASSES.transitioning);
    toggleClassName(this.element, state.visible, CLASSES.visible);
    toggleClassName(this.element, state.imageLoaded, CLASSES.imageLoaded);
  }

  show(image, caption) {
    clearTimeout(this.captionDelay);
    this.update({
      visible: true,
      showCaption: true,
      image: this._getResponsiveImageURL(image),
      lowResImage: this._getPlaceholderImageURL(image),
      caption: caption,
      imageLoaded: false,
      whereToFocusOnClose: document.activeElement,
    });
    this.hideCaptionAfterDelay();
  }

  hide() {
    this.update({
      visible: false,
      lowResImage: null,
    });
  }

  clear() {
    this.update({
      image: null,
      lowResImage: null,
      caption: null,
      imageLoaded: false,
      whereToFocusOnClose: false,
    });
  }

  hideCaptionAfterDelay() {
    this.captionDelay = setTimeout(() => {
      this.update({
        showCaption: false,
      });
    }, CAPTION_TIMEOUT);
  }

  // TODO: ought to strip any `w:` or `h:` params that may already be present
  _getPlaceholderImageURL(baseUrl) {
    return `${baseUrl}&w=${100}`;
  }

  // TODO: ought to strip any `w:` or `h:` params that may already be present
  _getResponsiveImageURL(baseUrl) {
    const windowAspectRatio = window.innerWidth / window.innerHeight;
    return windowAspectRatio >= 1
      ? `${baseUrl}&w=${window.innerWidth}`
      : `${baseUrl}&h=${window.innerHeight}`;
  }

  // NOTE: we need the triggers to be keyboard accessible, but we cannot rely on them being buttons. Ergo, we must make the tab-focusable
  _applyAccessibleAttributes() {
    [...document.querySelectorAll(this.triggerSelector)].forEach((el) => {
      el.setAttribute("tabindex", "0");
      el.setAttribute("aria-label", "Open a full-size image in a lightbox.");
    });
  }
}

export const lightbox = {
  current: null,
};

export const init = () => {
  // Initialize any instances of the Tab Module on any given page
  amantApp.addEventListener("turbo:load", () => {
    const element = document.getElementById("lightbox");
    lightbox.current = element ? new Lightbox(element) : null;
  });
};
