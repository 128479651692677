import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="program-archive-filter"
export default class extends Controller {
  static targets = [ "select" ]
  
  filter() {
    this.selectTarget.form.requestSubmit();
  }
}
