import amantApp from "../amant_app";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // optional for styling
import urlMetadata from "url-metadata";

const PROGRAM_SUBTYPES = ["series", "study lines", "publications"];

class LinkTooltip {
  constructor(element) {
    this.element = element;
    this.href = element.href;

    urlMetadata(this.href).then(
      (metadata) => {
        this.metadata = metadata;
        this.renderTooltip();
      },
      (error) => {
        console.warn(error);
      }
    );

    // uncomment below to disable click event for styling purposes
    // this.element.addEventListener("click", (e) => e.preventDefault());
  }

  static getTemplate() {
    if (!LinkTooltip.template) {
      LinkTooltip.template = document.querySelector(
        ".js-link-tooltip-template"
      );
    }
    return LinkTooltip.template;
  }

  renderTooltip() {
    const tooltip = LinkTooltip.getTemplate().cloneNode(true);
    const titleWrapper = tooltip.querySelector(
      ".js-link-tooltip-template-title"
    );
    const imageWrapper = tooltip.querySelector(
      ".js-link-tooltip-template-image"
    );
    const pathWrapper = tooltip.querySelector(".js-link-tooltip-template-path");

    let title = this.metadata.title.replace(" - Amant", "");
    titleWrapper.innerHTML = title;

    console.log(this.metadata["og:image"]);

    imageWrapper.src = this.metadata["og:image"];

    let path = this._constructPath(this.metadata.url);
    if (path.toLowerCase() === title.toLowerCase()) {
      path = "";
    }
    pathWrapper.innerHTML = path;

    if (this.element.classList.contains("button--long")) {
      tippy(this.element, {
        content: tooltip.innerHTML,
        allowHTML: true,
        arrow: false,
        placement: "top-end",
      }); 
    } else {
      tippy(this.element, {
        content: tooltip.innerHTML,
        allowHTML: true,
        arrow: false,
        placement: "right",
        // uncomment below to pause events for styling purposes
        // hideOnClick: false,
        // trigger: "click",
      });
    }
  }

  _constructPath(url) {
    let path = new URL(url).pathname
      .split("/")
      .filter((page) => page)
      .map((page) => page.replace(/(^[0-9]+)|([-,_])/g, " ").trim());

    // break the actual path convention and add programs if path is a subtype of programs
    if (path.some((x) => PROGRAM_SUBTYPES.includes(x))) {
      path = ["programs", ...path];
    }

    return path.join(" → ");
  }
}

export const linkTooltips = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the Tab Module on any given page
  amantApp.addEventListener("turbo:load", () => {
    linkTooltips.current = [
      ...document.querySelectorAll(".js-tooltip-link"),
    ].map((instance) => new LinkTooltip(instance));
  });
};
