import * as util from "../utilities";
import amantApp from "../amant_app";

export const init = () => {
  // Whenever turbo reloads the page, re-attach our events
  amantApp.addEventListener("turbo:load", () => {
    const siteHeader = document.getElementById("site-header");
    const siteNav = document.getElementById("site-header-nav");

    if (!siteHeader || !siteNav) {
      return;
    }

    // Gather some elements needed for event handlers
    const innerWrapper = document.querySelector(".site-header__inner"); // TODO: replace with ID
    const expandTrigger = document.getElementById("site-header-expand-trigger");
    const expandButton = document.getElementById("site-header-expand-button");
    const collapseButton = document.getElementById(
      "site-header-collapse-button"
    );
    const siteLogo = document.getElementById("site-header-logo");

    // Define initial state
    const state = {
      expanded: false,
      expansionTransition: false,
      scrolledAway: false,
      peakabooing: false,
      peakabooTransition: false,
      skipTransitions: false,
      scrollDepth: window.pageYOffset,
      navHeight: innerWrapper.getBoundingClientRect().height,
    };

    // Declare utility class names to ensure consistency when they are added/removed
    const classes = {
      expanded: "site-header--expanded",
      scrolledAway: "site-header--scrolled-away",
      peakabooing: "site-header--peakaboo",
      skipTransitions: "site-header--skip-transitions",
    };

    // Define a render function that has access to this page load's HTMLElement nodes
    const renderFn = (state) => {
      util.toggleClassName(
        siteHeader,
        state.scrolledAway,
        classes.scrolledAway
      );

      util.toggleClassName(
        siteHeader,
        state.skipTransitions,
        classes.skipTransitions
      );
      util.toggleClassName(siteHeader, state.peakabooing, classes.peakabooing);
      util.toggleClassName(siteHeader, state.expanded, classes.expanded);

      // Update aria attributes to reflect state of expansion and boomeranging
      expandButton.setAttribute("aria-expanded", state.expanded);

      return state;
    };

    const updateState = (update) => {
      const merged = Object.assign(state, update);
      renderFn(merged);
      return merged;
    };

    siteHeader.addEventListener("click", function (e) {
      // When the mobile view's "menu" button is clicked, open up the collapsed menu
      if (e.target === expandButton) {
        e.preventDefault();
      }

      if (e.target === collapseButton) {
        // e.preventDefault();
      }
    });

    // When the mouse enters the "peakabooing" trigger area, boomerang the nav back into view
    expandTrigger.addEventListener("mouseenter", (e) => {
      if (!state.expanded && amantApp.state.breakpoint === "3col") {
        updateState({
          expanded: true,
        });
      }
    });

    expandButton.addEventListener("click", () => {
      if (!state.expanded) {
        updateState({
          expanded: true,
        });
      }
    });

    collapseButton.addEventListener("click", (e) => {
      if (state.expanded) {
        updateState({
          expanded: false,
        });
      }
    });

    // When the mobile menu is expanded, clicking anywhere outside should collapse it
    // NOTE: uses named handler with event bus so that duplicate handlers
    //       do not accumulate as user navigates through site.
    amantApp.addEventListener("click", {
      name: "siteHeaderCollapseMenu",
      handler: (e) => {
        if (
          siteHeader.contains(e.target) ||
          !state.expanded ||
          !util.isMobile()
        ) {
          return;
        }

        updateState({
          expanded: false,
        });
      },
    });

    /*
    NOTE: when the nav scrolls out of view, we make the container `position: fixed`
          and use transforms to move all of the elements just north of the viewport, out of view.
          Most of the time, we want to move elements in and out with a transition;
          but for the initial change to `position: fixed`, we don't.
    */
    siteHeader.addEventListener("transitionend", function (e) {
      if (state.skipTransitions && e.propertyName === "transform") {
        updateState({
          skipTransitions: false,
        });
      }
    });

    // NOTE: uses named handler with event bus so that duplicate handlers
    //       do not accumulate as user navigates through site.
    const peakabooInThreshold = -100;
    const peakabooOutThreshold = 50;
    const minimumScrollForPeakaboo = window.innerHeight * 0.66;
    amantApp.addEventListener("scroll", {
      name: "siteHeaderScrollWatcher",
      handler: () => {
        // Make note of when the navigation scrolls away
        if (!state.scrolledAway && window.pageYOffset > state.navHeight) {
          updateState({
            scrolledAway: true,
            skipTransitions: true,
          });
          return;
        }

        if (!state.peakabooing && window.pageYOffset < state.navHeight) {
          updateState({
            scrolledAway: false,
          });
          return;
        } else if (state.peakabooing && window.pageYOffset < 1) {
          // At the document's top edge, the nav should reset to normal
          updateState({
            scrolledAway: false,
            peakabooing: false,
            expanded: false,
          });
          return;
        }

        // Make note of when the user changes scroll directions. If they scroll up,
        // the nav should "peakaboo" into view. If they scroll down, the "boomeranged"
        // state should also reset.
        const delta = window.pageYOffset - state.scrollDepth;
        // If scrolled down past threhold
        const update = {};
        if (delta > peakabooOutThreshold) {
          update.scrollDepth = window.pageYOffset;
          if (state.peakabooing) {
            update.peakabooing = false;
          }

          if (state.expanded) {
            update.expanded = false;
          }

          updateState(update);
        }
        // If scrolled up past threshold
        else if (
          delta < peakabooInThreshold &&
          window.pageYOffset >= minimumScrollForPeakaboo
        ) {
          update.scrollDepth = window.pageYOffset;
          // NOTE: we don't wanna peakaboo too close to the top, cus it causes too much motion
          if (!state.peakabooing && window.pageYOffset > 100) {
            update.peakabooing = true;
          }
          updateState(update);
        }
      },
    });

    // For mobile only, when the navigation is expanded, keyboard focus
    // should remain trapped within the header and navigation
    // NOTE: uses named handler with event bus so that duplicate handlers
    //       do not accumulate as user navigates through site.
    amantApp.addEventListener("focusin", {
      name: "siteHeaderFocusLock",
      handler: (e) => {
        if (state.expanded || !util.isMobile()) {
          return;
        }

        if (!siteHeader.contains(e.target)) {
          siteLogo.focus();
        }
      },
    });

    // Disabling transition between mobile and desktop view
    // amantApp.addEventListener("resize", {
    //   name: "siteHeaderResize",
    //   handler: (e) => {
    //     updateState({
    //       expanded: !util.isMobile(),
    //       navHeight: innerWrapper.getBoundingClientRect().height,
    //     });
    //   },
    // });
  });
};
