import amantApp from "../amant_app";
import $ from "jquery";
import "bootstrap-datepicker";

// Enable jQuery on the window scope to test.
// window.$ = $;

// Removes any rows from the datepicker that contain blank days.
function removeBlankWeeks() {
  const weeks = $('.datepicker-days tbody tr');
  for (const week of weeks) {
    if (week.lastElementChild.classList.contains('old') ||
    week.firstElementChild.classList.contains('new')) {
      week.remove();
    };
  };
}

// Style the arrows appropriately.
function styleArrows() {
  const nextArrowDiv = document.createElement("div");
  nextArrowDiv.classList.add('next-arrow');
  const nextArrow = $('.datepicker-days th.next')[0];
  nextArrow.removeChild(nextArrow.firstChild);
  nextArrow.appendChild(nextArrowDiv);
  
  const prevArrowDiv = document.createElement("div");
  prevArrowDiv.classList.add('prev-arrow');
  const prevArrow = $('.datepicker-days th.prev')[0];
  prevArrow.removeChild(prevArrow.firstChild);
  prevArrow.appendChild(prevArrowDiv);
}

// Currently this refreshes the page but ideally it will keep the user on the same page and just refresh the elements.
function showDateEvents(change_event, date_selector, picker_element) {
  const isViewAll = new URLSearchParams(window.location.search).get('all');
  if (isViewAll) {
    return;
  } else {
    date_selector = change_event.date; // Should look into avoiding this state mutation.
    picker_element.data('datepicker').update(change_event.date);
    const date_query = `date=${change_event.date.getFullYear()}-${change_event.date.getMonth()+1}-${change_event.date.getDate()}`;
    window.location.search = date_query;
  }
}

// Utility to test shallow equality of two dates yyyymmdd == yyyymmdd.
function datesAreEqual(date1, date2) {
  if (date1.getFullYear() == date2.getFullYear() && 
      date1.getMonth() == date2.getMonth() && 
      date1.getDate() == date2.getDate()-1) {
    return true;
  } else {
    return false;
  }
}

async function fetchMonthlyEvents(month){
  const res = await fetch(`/month-events?year=${month.getFullYear()}&month=${month.getMonth()}`);
  if (res.status == 200) {
    const events = await res.json();
    return events;
  }
}

function hasEvent(date, events) {
  const strDate = new Date(date).toISOString().substr(0, 10);
  if (events) {
    return events.includes(strDate);
  }
}

function highlightDate(date, startDate, monthlyEvents, highlightSelected) {
  if (date.toDateString() == new Date().toDateString()) {
    return { classes: 'is-selected' }
  }
  else if (datesAreEqual(date, startDate) && highlightSelected) {
    return { classes: 'is-selected' }
  }
  else if(hasEvent(date, monthlyEvents)) {
    return { classes: 'has-event' }
  } else {
    return true;
  }
}

async function constructPicker(selector, startDate) {
  let highlightSelected = false;
  if (window.location.search.includes('date')) {
    highlightSelected = true;
    const dateStr = new URLSearchParams(window.location.search).get('date');
    startDate = new Date(dateStr);
    startDate.setDate(startDate.getDate()+1);
  }

  let picker = fetchMonthlyEvents(startDate).then(monthlyEvents => {
    const result = $(selector).datepicker({
      autoclose: false,
      clearBtn: false,
      beforeShowDay: (date) => { 
        return highlightDate(date, startDate, monthlyEvents, highlightSelected); 
      },
      beforeShowMonth: (date) => { 
        removeBlankWeeks();
      }
    });
    
    return result;
  });

  return picker;
}

export const init = () => {
  // Must load the picker after the page loads far enough to render it.
  amantApp.addEventListener("turbolinks:load", () => {
    const today = new Date();
    let date_selector = today;
    constructPicker('.date-picker__calendar', today).then( (element) => {
      $('.datepicker-switch').on("click", () => false);
      $('.clear').on("click", (e) => { 
        e.preventDefault();
        window.location = `${location.protocol}//${location.host}/programs#calendar`;
      })
  
      if (element.length > 0) {
        styleArrows();
        element.on('changeDate', (e) => showDateEvents(e, date_selector, element));
      }
    });

  });
};