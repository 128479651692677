/* JavaScript for TabModule */
import amantApp from "../amant_app";

const ACTIVE_CLASS_NAME = "tab-module__panel--active";

class TabModule {
  constructor(root) {
    this.element = root;

    // Create a mapping to connect radio buttons with their associated
    // panels, using a shared slug.
    const radioButtons = root.querySelectorAll("input[type=radio]");
    this.pairings = new Map();
    [...radioButtons].forEach((radio) => {
      const slug = radio.getAttribute("data-slug");
      const panel = document.getElementById(`panel-${slug}`);
      this.pairings.set(radio, panel);
    });

    // Initial state
    this.state = {
      activePanel: null,
    };

    // Render initial state (force the initial tab to be the first one)
    // (Some browsers "remember" the last clicked button from previous sessions)
    this.update(radioButtons[0]);

    // When tabs are selected, change panels
    root.addEventListener("change", (e) => {
      if (!e.target.matches('input[type="radio"]')) {
        return;
      }

      this.update(e.target);
    });
  }

  update(activeRadioButton) {
    activeRadioButton.checked = true;
    const panel = this.pairings.get(activeRadioButton);
    if (!panel) {
      return;
    }

    if (this.state.activePanel) {
      this.state.activePanel.classList.remove(ACTIVE_CLASS_NAME);
      this.state.activePanel.setAttribute("aria-hidden", true);
    }

    panel.classList.add(ACTIVE_CLASS_NAME);
    panel.setAttribute("aria-hidden", false);
    this.state.activePanel = panel;
  }
}

export const tabModules = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the Tab Module on any given page
  amantApp.addEventListener("turbo:load", () => {
    tabModules.current = [...document.querySelectorAll(".js-tab-module")].map(
      (instance) => new TabModule(instance)
    );
  });
};
