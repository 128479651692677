import { marqueeModules } from "../magic_modules/marquee_module";

class LEDMarquee {
  constructor(element) {
    this.element = element;
    this.ledWrapper = this.element.querySelector(".led-wrapper");
    this.marquees = this.element.querySelectorAll(".led-wrapper .marquee-module");
    this.children = marqueeModules.current;
    this.childCount = this.children.length;
    this.timesReordered = 0;

    // Init order for all children and add loop event listener.
    this.children.forEach((child, i) => {
      child.element.style.order = i+1;
      child.element.addEventListener("loopevent", (e) => {
        if (this.children[0].element == e.detail)
        console.log(`Top element was: `, e.detail);
        this.rotateMarquees();
      });
    });

    // Uncomment below for debugging.
    // window.wrapper = this.ledWrapper;
    // window.marquees = this.children;
    // try {
      document.getElementById("rotator").addEventListener("click", () => this.rotateMarquees());
      document.getElementById("reset").addEventListener("click", () => this.resetMarquees());
    // } catch(e) {
    //   console.log(e);
    // }
  } // End Constructor

  rotateMarquees() {
    this.children.forEach((child, i) => {
      let currentOrder = Number(child.element.style.order)
      if (currentOrder == 1)
        currentOrder = this.childCount+1;
      child.element.style.order = (currentOrder-1);
      child.restartAnimation();
    });
    
    this.children.push(this.children.shift());
    this.timesReordered++;
    if (this.timesReordered == this.childCount) {
      location.reload();
    }
  }

  resetMarquees() {
    this.children.forEach((child) => {
      child.restartAnimation();
    })
  }
}

export const ledMarquees = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the FileUploader on any given page
  document.addEventListener("turbo:load", () => {
    let instance = document.querySelector(".controller--led_pages.action--show");
    if(instance) {
      new LEDMarquee(instance);
    }
  });
};