import amantApp from "../amant_app";
import { newsletterModal } from "./newsletter-modal";

class Footer {
  constructor(element) {
    // Elements
    this.element = element;
    const newsletterTrigger = document.getElementById(
      "footer-newsletter-signup-trigger"
    );

    // Events
    newsletterTrigger.addEventListener("click", () => {
      if (newsletterModal && newsletterModal.current) {
        newsletterModal.current.open(newsletterTrigger);
      }
    });
  }
}

export const footer = {
  current: null,
};

export const init = () => {
  amantApp.addEventListener("turbo:load", () => {
    const element = document.getElementById("site-footer");
    if (element) {
      footer.current = new Footer(element);
    }
  });
};
