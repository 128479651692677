/* JavaScript for DownloadsModule */
import $ from "jquery";
import "url-polyfill";

const RESULTS_PER_PAGE = 6; //must match DownloadsModule::RESULTS_PER_PAGE

class DownloadsModule {
  constructor(element) {
    this.element = element;
    this.content = this.element.querySelector(".js-downloads-module-items");
    this.link = this.element.querySelector(".js-downloads-module-load-button");

    if (this.link) {
      this.link.addEventListener("click", (e) => {
        this.loadMore(e);
      });
    }
  }

  loadMore = async (e) => {
    e.preventDefault();
    const data = await $.get(this.link.href, $(e.target).serialize());
    this.handleAjax(data);
  };

  handleAjax(data) {
    this.content.innerHTML += data;
    this.updatePage();
  }

  updatePage() {
    const url = new URL(this.link.href);
    const currentPage = parseInt(url.searchParams.get("page"));
    const currentDownloadItems = this.element.querySelectorAll(
      ".js-downloads-module-item"
    );

    if (currentPage * RESULTS_PER_PAGE > currentDownloadItems.length) {
      this.link.classList.add("visually-hidden");
    } else {
      url.searchParams.set("page", currentPage + 1);
      this.link.href = url.href;
    }
  }
}
export const downloadsModules = {
  current: [],
};
export const init = () => {
  // Initialize all instances and save references in an exported collection
  document.addEventListener("turbo:load", () => {
    downloadsModules.current = [
      ...document.querySelectorAll(".js-downloads-module"),
    ].map((instance) => new DownloadsModule(instance));
  });
};
