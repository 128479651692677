/* JavaScript for AccordionModule */

const ACTIVE_CLASS_NAME = "accordion-module__accordion--active";

export class AccordionModule {
  constructor(root) {
    this.element = root;

    const accordions = root.querySelectorAll(".accordion-module__accordion");

    accordions.forEach((accordion) => {
      const header = accordion.querySelector(".accordion-module__header");

      header.addEventListener("click", (e) => {
        this.toggle(accordion, header);
      });
    })
  }

  toggle(accordion, header) {
    let isExpanded = false;

    accordion.classList.toggle(ACTIVE_CLASS_NAME);

    if ( accordion.classList.contains(ACTIVE_CLASS_NAME) ) {
      isExpanded = true;
    } else {
      isExpanded = false;
    }

    header.setAttribute("aria-expanded", isExpanded);
  }
}

export const accordionModules = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the AccordionModule on any given page
  document.addEventListener("turbo:load", () => {
    accordionModules.current = [
      ...document.querySelectorAll(".js-accordion-module"),
    ].map((instance) => new AccordionModule(instance));
  });
};
