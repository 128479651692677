/* JavaScript for NewsModule */
import $ from "jquery";
import "url-polyfill";

const RESULTS_PER_PAGE = 6; //must match NewsModule::RESULTS_PER_PAGE

class NewsModule {
  constructor(element) {
    this.element = element;
    this.content = this.element.querySelector(".js-news-module-items");
    this.link = this.element.querySelector(".js-news-module-load-button");

    if (this.link) {
      this.link.addEventListener("click", (e) => {
        this.loadMore(e);
      });
    }
  }

  loadMore = async (e) => {
    e.preventDefault();
    const data = await $.get(this.link.href, $(e.target).serialize());
    this.handleAjax(data);
  };

  handleAjax(data) {
    this.content.innerHTML += data;
    this.updatePage();
  }

  updatePage() {
    const url = new URL(this.link.href);
    const currentPage = parseInt(url.searchParams.get("page"));
    const currentNewsItems = this.element.querySelectorAll(".js-news-item");

    if (currentPage * RESULTS_PER_PAGE > currentNewsItems.length) {
      this.link.classList.add("visually-hidden");
    } else {
      url.searchParams.set("page", currentPage + 1);
      this.link.href = url.href;
    }
  }
}

export const newsModules = {
  current: [],
};

export const init = () => {
  // Initialize all instances and save references in an exported collection
  document.addEventListener("turbo:load", () => {
    newsModules.current = [...document.querySelectorAll(".js-news-module")].map(
      (instance) => new NewsModule(instance)
    );
  });
};
